<template>
  <div class="mt-0-px pb-0">
    <div id="etf" v-if="angemeldeterMitarbeiter">
      <navigation-arvea :angemeldeterMitarbeiter="angemeldeterMitarbeiter"/>

      <!-- GRAPH -->
      <loading-overlay v-show="loading"></loading-overlay>
      <div
          v-if="!loading"
          class="row etfGraph mb-5 px-5 d-flex justify-content-center etfGraphRow"
      >
        <div class="col-xl-3 d-flex flex-column p-0">
          <div class="title mb-5">
            <h1>Symbols</h1>
            <!--<span>Untertitel</span>-->
          </div>
          <div class="">
            <div class="form-group mb-3">
              <div class="form-group position-relative mb-3">
                <label for="symbol">Symbol</label>
                <input
                    type="text"
                    class="filterInput"
                    v-model="symbolSearchQuery"
                    @input="filterSymbols()"
                    placeholder="Type to search symbols..."
                    autocomplete="off"
                    @keydown.enter="handleInputEnter()"
                />
                <ul
                    v-if="filteredSymbols.length > 0 && showSymbolDropdown"
                    class="dropdown-menu show"
                >
                  <li
                      v-for="(symbol, index) in filteredSymbols"
                      :key="index"
                      class="dropdown-item"
                      @click="handleNewSymbol(symbol)"
                  >
                    {{ symbol.designation }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="">
            <div class="form-group etfDropdown mb-3">
              <v-select
                  v-model="max"
                  label="Days"
                  :options="days"
                  placeholder="27"
                  :clearable="false"
                  name="days"
              >
                <span slot="no-options"> Keine Optionen </span>
              </v-select>
              <label for="days">Days</label>
            </div>
          </div>
          <div class="etfInfos">
            <div class="etfSymbols">
              {{ this.symbol.symbol }}
            </div>
            <div class="etfDates">
              {{ this.datacollection.datasets[0].datum[0] }} -
              {{
                this.datacollection.datasets[0].datum[
                this.datacollection.datasets[0].datum.length - 1
                    ]
              }}
            </div>
          </div>
        </div>
        <div class="col-xl-7 etfGraph_graph">
          <etf-chart :datacollection="datacollection" :key="etfChartKey"/>
        </div>
      </div>

      <!-- TABLE -->
      <div class="row m-auto" style="max-width: 85%" v-if="!loading">
        <div class="col-xl-12 d-flex align-items-center">
          <div class="row col-xl-12">
            <div class="col-xl-12 my-5">
              <div class="scrollable-table">
                <b-table
                    class="etfTable"
                    ref="selectableTable"
                    tbody-tr-class="item"
                    :items="list"
                    :fields="listFields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                    :sort-compare="sortDates"
                    :busy="isBusy"
                    striped
                    hover
                    sort-icon-left
                    fixed
                    selectable
                    borderless
                    select-mode="multi"
                >
                  <template #head()="{ label, field: { key } }">
                    {{ label }}
                    <template>
                      <svg
                          v-if="sortBy !== key"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 320 512"
                          style="
                          width: 0.65rem;
                          height: 1rem;
                          fill: #213056;
                          margin: 0 0 3px 5px;
                        "
                      >
                        <path
                            d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z"
                        />
                      </svg>
                      <svg
                          v-else-if="
                          (key !== 'symbol' && sortDesc) ||
                          (key === 'symbol' && !sortDesc)
                        "
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 320 512"
                          style="
                          width: 0.65rem;
                          height: 1rem;
                          fill: #213056;
                          margin: -7px 0 0 5px;
                        "
                      >
                        <path
                            d="M311.9 335.1l-132.4 136.8C174.1 477.3 167.1 480 160 480c-7.055 0-14.12-2.702-19.47-8.109l-132.4-136.8C-9.229 317.8 3.055 288 27.66 288h264.7C316.9 288 329.2 317.8 311.9 335.1z"
                        />
                      </svg>
                      <svg
                          v-else-if="key === 'symbol' && sortDesc"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 320 512"
                          style="
                          width: 0.65rem;
                          height: 1rem;
                          fill: #213056;
                          margin: 0 0 0 5px;
                        "
                      >
                        <path
                            d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224z"
                        />
                      </svg>
                      <svg
                          v-else
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 320 512"
                          style="
                          width: 0.65rem;
                          height: 1rem;
                          fill: #213056;
                          margin: 0 0 0 5px;
                        "
                      >
                        <path
                            d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224z"
                        />
                      </svg>
                    </template>
                  </template>
                </b-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import page from "@/mixins/Page";
import store from "@/store";
import Api from "@/Api";
import EtfChart from "../components/charts/EtfChart.vue";
import LoadingOverlay from "@/components/global/LoadingOverlay";
import NavigationArvea from "@/components/Navigation/NavigationArvea";

export default {
  name: "ETF",
  components: {
    EtfChart,
    LoadingOverlay,
    NavigationArvea,
  },
  mixins: [page],
  store,
  metaInfo() {
    return {
      titleTemplate: () => {
        return "Arvea Universe ETF";
      },
    };
  },
  data() {
    return {
      clicks: [],
      expandedRows: [],
      page: 0,
      loading: false,
      max: 27,
      symbol: {},
      days: [],
      anzahlProPage: 100,
      list: [],
      symbolSearchQuery: "",
      filteredSymbols: [],
      showSymbolDropdown: false,
      ladeFehler: false,
      infiniteId: +new Date(),
      sortBy: "",
      sortDesc: true,
      detailSortBy: "date",
      detailSortDesc: true,
      sortDirection: "desc",
      isBusy: false,
      startDates: [],
      etfChartKey: 0,
      datacollection: {
        labels: [
          27, 26, 25, 24, 23, 22, 21, 20, 19, 18, 17, 16, 15, 14, 13, 12, 11,
          10, 9, 8, 7, 6, 5, 4, 3, 2, 1,
        ],
        datasets: [
          {
            data: [],
            inverseData: [],
            label: "Datenreihe n1",
            borderColor: "#3e95cd",
            fill: false,
            datum: [],
          },
        ],
      },
      listFields: [
        {
          key: "date",
          sortable: true,
          label: "Date",
        },
        {
          key: "value",
          sortable: true,
          label: "Value",
          class: "text-right",
          formatter: (value) => {
            return this.formatNum(value);
          },
        },
        {
          key: "values[3].zvalue",
          sortable: true,
          label: "27",
          class: "text-right",
          tdClass: (value) => {
            return this.getGreen(value);
          },
          formatter: (value) => {
            return this.formatNum(value);
          },
        },
        {
          key: "values[2].zvalue",
          sortable: true,
          label: "36",
          class: "text-right",
          tdClass: (value) => {
            return this.getGreen(value);
          },
          formatter: (value) => {
            return this.formatNum(value);
          },
        },
        {
          key: "values[1].zvalue",
          sortable: true,
          label: "45",
          class: "text-right",
          tdClass: (value) => {
            return this.getGreen(value);
          },
          formatter: (value) => {
            return this.formatNum(value);
          },
        },
        {
          key: "values[0].zvalue",
          sortable: true,
          label: "54",
          class: "text-right",
          tdClass: (value) => {
            return this.getGreen(value);
          },
          formatter: (value) => {
            return this.formatNum(value);
          },
        },
      ],
      updateKey: "",
      counter: 0,
    };
  },
  watch: {
    max: function (value) {
      if (value) {
        this.datacollection.labels = this.range(value, 1);
        this.assignSelectedSymbol();
        this.setYAxes();
        this.etfChartKey++;
      }
    },
    symbol: function (value) {
      if (value) {
        this.assignSelectedSymbol(value);
        this.setYAxes();
        this.etfChartKey++;
      }
    },
  },
  computed: {
    permissions() {
      return this.$store.state.permissions;
    },
  },
  created() {
  },
  mounted() {
    let symbolId = this.$route.params.id
    this.get(symbolId ? symbolId : "baa89c74-dae6-42a5-8e52-4dd53b2724c0") //Default id for AAPL from live system;
    this.getSymbols()
  },

  methods: {
    setYAxes() {
      let maxValue = 3.0;
      let minValue = -3.0;

      this.datacollection?.datasets?.forEach((dataset) => {
        let datasetMax = 0;
        let datasetMin = 0;
        /*Array bereinigt, falls Werte undefined oder null sind, da sonst Math.max/min.apply nicht
        richtig funktioniert*/
        let cleanedDataset = [];

        dataset.data.forEach((data, index) => {
          if (data) {
            //data = data - data * 2; //invertierung rausgenommen 27.11.2024
            cleanedDataset.push(data);
            dataset.data[index] = data;
          }
        });

        datasetMax = Math.max.apply(Math, cleanedDataset);
        datasetMin = Math.min.apply(Math, cleanedDataset);

        if (datasetMax > maxValue) {
          maxValue = Math.ceil(datasetMax / 0.5) * 0.5;
        }
        if (datasetMin < minValue) {
          minValue = Math.floor(datasetMin / 0.5) * 0.5;
        }
      });
      this.datacollection.yMax = maxValue;
      this.datacollection.yMin = minValue;
    },
    assignSelectedSymbol(symbol = this.symbol) {
      let selectedSymbol = {
        data: symbol.dates
            .slice(0, this.max)
            .map((i) => i.values?.find((value) => value.max === this.max)?.zvalue)
            ?.reverse(),
        label: symbol.label,
        borderColor: symbol.borderColor,
        fill: symbol.fill,
        datum: symbol.dates
            .slice(0, this.max)
            .map((i) => i.date)
            .reverse(),
      };

      let json = Object.assign({}, selectedSymbol);
      this.datacollection.datasets = [json];
    },
    range(size, startAt = 0) {
      return [...Array(size).keys()].map((i) => i + startAt).reverse();
    },

    getSymbols() {
      this.loading = true;
      Api.get("symbol-list/", {})
          .then((response) => {
            this.symbols = response.data
          }).finally(() => {
        this.loading = false;
      });
    },

    get(symbolId) {
      this.loading = true;

      Api.get("symbol-data/", {
        params: {symbolId: symbolId},
      })
          .then((response) => {
            this.list = response.data.dates;
            this.days = response.data.days;
            this.symbol = response.data;
          })
          .finally(() => {
            this.loading = false;
          });
    },

    getGreen(value) {
      if (value && value >= 1.5) {
        return "table-success";
      }
    },


    formatNum(num) {
      if (num != null) {
        if (typeof num == "number") {
          var str = num.toLocaleString("en-US");
          str = str.replace(/\./, ".");
          str = str.replace(/,/g, "'");
          return str;
        } else return num;
      } else {
        return "-";
      }
    },

    sortDates(datumA, datumB, key) {
      if (key !== "date") {
        return false;
      } else {
        let a = null;
        let b = null;

        if (key.includes(".")) {
          a = this.resolve(key, datumA);
          b = this.resolve(key, datumB);
        } else {
          a = datumA[key];
          b = datumB[key];
        }
        if (a && b) {
          a = a.split(".");
          b = b.split(".");
          a =
              parseInt(a[2], 10) * 10000 +
              parseInt(a[1], 10) * 100 +
              parseInt(a[0]);
          b =
              parseInt(b[2], 10) * 10000 +
              parseInt(b[1], 10) * 100 +
              parseInt(b[0]);
          return a - b;
        } else {
          return false;
        }
      }
    },
    handleInputEnter() {
      let matchingSymbol = this.symbols.find(symbol =>
          symbol.designation.trim().toLowerCase() === this.symbolSearchQuery.toLowerCase()
      );

      if (matchingSymbol !== undefined) {
        this.handleNewSymbol(matchingSymbol);
      }

    },
    handleNewSymbol(symbol) {
      this.showSymbolDropdown = false
      this.symbolSearchQuery = symbol.designation;
      this.get(symbol.id);
    },

    filterSymbols() {
      if (this.symbolSearchQuery === "") {
        this.filteredSymbols = []
      } else {
        this.showSymbolDropdown = true;
        this.filteredSymbols = this.symbols.filter((symbol) =>
            symbol.designation.trim().toLowerCase().includes(this.symbolSearchQuery.toLowerCase())
        );
      }
    },
  },
};
</script>
<style lang="scss">
body {
  background: white;
  color: #213056;
  overflow-x: hidden;
}

#etf .scrollable-table {
  height: 500px;
  overflow-y: scroll;
}

#etf .scrollable-details-table {
  height: 380px;
  overflow-y: scroll;
}

#etf .banner {
  background-color: #e6edff;
  height: 300px;
  width: 100vw;
}

#etf .banner h1 {
  padding-top: 65px;
  font-weight: 700;
  color: #c7cdde;
}

#etf .banner p {
  padding-top: 10px;
  font-size: 16px;
  padding-right: 30%;
}

#etf .banner > div:last-child {
  display: flex;
  align-items: center;
}

#etf .etfGraph_graph {
  margin-top: 100px;
}

#etf th {
  cursor: pointer;
}

#etf .etfTable {
  cursor: pointer;
}

#etf .detailTable {
  cursor: default;
}

#etf .etfDropdown {
  position: relative;
  max-width: 75%;
}

#etf .title h1 {
  font-size: 36px;
}

#etf .title span {
  font-size: 17px;
}

#etf .etfGraphRow {
  margin-top: 80px;
}

#etf .etfInfos {
  margin-top: 70px;
  padding: 50px 50px;
  background: #213056;
  border-radius: 10px;
  max-width: 75%;
  color: white;
}

#etf .etfSymbols {
  font-size: 30px;
  font-weight: bold;
}

#etf .etfDates {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.05rem;
}

.filterInput {
  height: 30px;
  width: 75%;
}

.position-relative {
  position: relative;
}

.dropdown-menu {
  width: 75%;
  max-height: 400px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1050;
  user-select: none;
}

.dropdown-item {
  cursor: pointer;
}
</style>
