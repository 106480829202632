<template>
  <div class="LPPEvolutionsPerGrowthCycle p-3">
    <div class="goBack mb-5 d-flex align-items-center" id="LppEvolutionPerGrowthCycle">
      <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="currentColor"
          class="bi bi-chevron-left"
          viewBox="0 0 16 16"
      >
        <path
            fill-rule="evenodd"
            d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
        />
      </svg>
      <h4 class="m-0" @click="hideEvolutions">Overview</h4>
    </div>
    <div class="mb-3 d-flex align-items-center">
      <h4 class="mr-4 my-auto">Filter by Symbol:</h4>
      <div class="form-group p-0 m-0 mr-2">
        <input
            class="filterInput"
            v-model="filterSymbol"
            @keyup.enter="filterTable"
        />
      </div>
      <div class="btn btn-primary mr-2" @click="filterTable">Go</div>
      <div class="btn btn-secondary mr-2" @click="reset">Reset</div>
      <div class="form-group col-2 p-0 m-0 mr-2 selectedGrowthCycle">
        <v-select
            v-model="selectedGrowthCycle"
            label="name"
            placeholder="Growthcycle"
            :options="growthCycleIDs"
            name="growthcycle"
            :clearable="true"
        >
        </v-select>
      </div>
    </div>

    <div
        class="spinnerRow row d-flex justify-content-center align-items-center"
        v-if="tableLoading"
    >
      <b-spinner class="lppSpinner"></b-spinner>
    </div>
    <div v-if="!tableLoading">
      <b-table
          class="LPPEvolutionsPerGrowthCycleTbl"
          style="overflow: scroll"
          ref="LPPEvolutionsPerGrowthCycleTbl"
          tbody-tr-class="item"
          :items="listData ? listData : []"
          :fields="fields"
          striped
          fixed
          borderless
          v-show="listData.length !== 0"
      >
        <template #cell(show_details)="row">
          <button
              @click="showEtfChart(row)"
              class="button_strikeevolution"
          >
            {{ row.detailsShowing ? "Hide" : "Show" }} data
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
            >
              <g id="expand_circle_down" transform="translate(9490 -6637)">
                <rect
                    id="frame_24x24"
                    width="24"
                    height="24"
                    transform="translate(-9490 6637)"
                    fill="none"
                />
                <path
                    id="expand_circle_down-2"
                    data-name="expand_circle_down"
                    d="M15.421,18.837a.839.839,0,0,0,1.158,0L20.2,15.218a.607.607,0,0,0,.174-.449.564.564,0,0,0-.2-.449.631.631,0,0,0-.926,0L16,17.563l-3.271-3.271a.524.524,0,0,0-.449-.188.776.776,0,0,0-.478.188.7.7,0,0,0,0,.926ZM16,27a11.124,11.124,0,0,1-4.328-.839,10.675,10.675,0,0,1-5.833-5.833A11.124,11.124,0,0,1,5,16a11.05,11.05,0,0,1,.839-4.3A10.753,10.753,0,0,1,8.17,8.2,10.988,10.988,0,0,1,16,5,10.921,10.921,0,0,1,27,16a10.988,10.988,0,0,1-3.2,7.83,10.794,10.794,0,0,1-3.488,2.33A11.018,11.018,0,0,1,16,27ZM16,16Zm0,9.7A9.542,9.542,0,0,0,25.7,16,9.542,9.542,0,0,0,16,6.3,9.542,9.542,0,0,0,6.3,16,9.542,9.542,0,0,0,16,25.7Z"
                    transform="translate(-9494 6633)"
                />
              </g>
            </svg>
          </button>
        </template>

        <template #row-details="">
          <div
              id="LPPEvolutionsPerGrowthCycleTbl"
              class="spinnerRow row d-flex justify-content-center align-items-center"
              v-if="isBusy"
          >
            <b-spinner class="lppSpinner"></b-spinner>
          </div>
          <div class="col-12 pl-0 form-group">
            <div class="graphRangeLinks d-flex justify-content-center">
              <p
                  class="graphRangeLink px-2"
                  :style="range.selected ? 'color: #3e95cd;' : ''"
                  v-for="(range, index) in graphRanges"
                  :key="index"
                  @click="setSelectedGraphRange(range)"
              >
                {{ range.range }}D
              </p>
            </div>
            <button class="btn btn-nav btn-primary mr-2" @click="goToSymbol">Open Symbol
                <svg class="icon" xmlns="http://www.w3.org/2000/svg" height="14" width="14" viewBox="0 0 512 512">
                  <!--!Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2025 Fonticons, Inc.-->
                  <path fill="#ffffff"
                        d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32l82.7 0L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3l0 82.7c0 17.7 14.3 32 32 32s32-14.3 32-32l0-160c0-17.7-14.3-32-32-32L320 0zM80 32C35.8 32 0 67.8 0 112L0 432c0 44.2 35.8 80 80 80l320 0c44.2 0 80-35.8 80-80l0-112c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 112c0 8.8-7.2 16-16 16L80 448c-8.8 0-16-7.2-16-16l0-320c0-8.8 7.2-16 16-16l112 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L80 32z"/>
                </svg>
            </button>
          </div>
          <div v-if="symbol" class="col-xl-12">
            <etf-chart :datacollection="datacollection" :key="etfChartKey"/>
          </div>
        </template>
      </b-table>
    </div>
    <div v-if="listData.length === 0 && filterSymbol" class="noRows">
      Es wurden keine Symbole unter der Eingabe "{{ this.filterSymbol }}"
      gefunden.
    </div>
  </div>
</template>

<script>
//import { BSpinner } from "bootstrap-vue";
import {BSpinner} from "bootstrap-vue";
import Api from "@/Api";
import EtfChart from "@/components/charts/EtfChart.vue";

export default {
  name: "LPPEvolutionsPerGrowthCycle",
  components: {
    EtfChart,
    BSpinner,
  },
  mixins: [],
  props: {
    evolutionPerGrowthCycle: {
      type: Array,
      required: true,
    },
    growthCycleIDs: {
      type: Array,
      required: true,
    },
    growthCycleName: {
      type: String,
      required: false,
    },
    tableLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  metaInfo() {
    return {};
  },
  data() {
    return {
      isBusy: false,
      sortBy: "",
      sortDesc: true,
      filterSymbol: "",
      initialListData: null,
      listData: null,
      filter: "",
      selectedGrowthCycle: this.growthCycleName,
      fields: [
        {key: "symbol_name", label: "Symbol"},
        {
          key: "growth_cycles[6].growth_cyle",
          label: `${this.evolutionPerGrowthCycle[0].growth_cycles[6].date}`,
          tdClass: (value) => {
            return this.getColor(value);
          },
        },
        {
          key: "growth_cycles[5].growth_cyle",
          label: `${this.evolutionPerGrowthCycle[0].growth_cycles[5].date}`,
          tdClass: (value) => {
            return this.getColor(value);
          },
        },
        {
          key: "growth_cycles[4].growth_cyle",
          label: `${this.evolutionPerGrowthCycle[0].growth_cycles[4].date}`,
          tdClass: (value) => {
            return this.getColor(value);
          },
        },
        {
          key: "growth_cycles[3].growth_cyle",
          label: `${this.evolutionPerGrowthCycle[0].growth_cycles[3].date}`,
          tdClass: (value) => {
            return this.getColor(value);
          },
        },
        {
          key: "growth_cycles[2].growth_cyle",
          label: `${this.evolutionPerGrowthCycle[0].growth_cycles[2].date}`,
          tdClass: (value) => {
            return this.getColor(value);
          },
        },
        {
          key: "growth_cycles[1].growth_cyle",
          label: `${this.evolutionPerGrowthCycle[0].growth_cycles[1].date}`,
          tdClass: (value) => {
            return this.getColor(value);
          },
        },
        {
          key: "growth_cycles[0].growth_cyle",
          label: `${this.evolutionPerGrowthCycle[0].growth_cycles[0].date}`,
          tdClass: (value) => {
            return this.getColor(value);
          },
        },
        {
          key: "show_details",
          sortable: false,
          label: this.$t("Symbol"),
          tdClass: "align-items-center",
        },
      ],
      strikes: [],
      yMaxStrikePrice: 0,
      yMinStrikePrice: 0,
      updateKey: "",
      counter: 0,
      datacollection: {
        labels: [
          27, 26, 25, 24, 23, 22, 21, 20, 19, 18, 17, 16, 15, 14, 13, 12, 11,
          10, 9, 8, 7, 6, 5, 4, 3, 2, 1,
        ],
        datasets: [
          {
            data: [],
            inverseData: [],
            label: "Datenreihe n1",
            borderColor: "#3e95cd",
            fill: false,
            datum: [],
          },
        ],
      },
      days: [],
      list: [],
      max: 27,
      symbol: {},
      etfChartKey: 0,
      graphRanges: [
        {
          range: 27,
          selected: false,
        },
        {
          range: 36,
          selected: false,
        },
        {
          range: 45,
          selected: false,
        },
        {
          range: 54,
          selected: false,
        },
      ],
      selectedGraphRange: {
        range: 27,
        selected: true,
      },
    };
  },
  watch: {
    selectedGrowthCycle() {
      this.$emit("growthCycleChanged", this.selectedGrowthCycle);
    },
    evolutionPerGrowthCycle() {
      this.initialListData = this.evolutionPerGrowthCycle;
      this.filterTable();
    },
    max: function (value) {
      if (value) {
        this.datacollection.labels = this.range(value, 1);
        this.assignSelectedSymbol();
        this.setYAxes();
        this.etfChartKey++;
      }
    },
    symbol: function (value) {
      if (value) {
        this.assignSelectedSymbol(value);
        this.setYAxes();
        this.etfChartKey++;
      }
    },
  },
  computed: {},
  created() {
    this.initialListData = this.evolutionPerGrowthCycle;
    this.filterTable();
  },
  mounted() {
  },
  methods: {
    getColor(value) {
      if (value.includes("D")) {
        return "table-success";
      } else {
        return "table-danger";
      }
    },
    hideEvolutions() {
      if (!this.tableLoading) {
        this.$emit("hideEvolutions");
      }
    },

    filterTable() {
      this.filter = this.filterSymbol;

      if (this.filter && this.filter !== "") {
        this.listData = this.initialListData.filter((data) => {
          return data.symbol_name
              .toLowerCase()
              .trim()
              .includes(this.filter.toLowerCase());
        });
      } else {
        this.listData = this.initialListData;
      }
    },
    reset() {
      this.listData = this.initialListData;
      this.filter = "";
      this.filterSymbol = "";
    },
    showEtfChart(row) {
      this.isBusy = true
      Api.get("symbol-data/", {
        params: {symbolId: row.item.symbol_id},
      }).then((response) => {
        this.list = response.data.dates;
        this.days = response.data.days;
        this.symbol = response.data;
      }).finally(() => {
        this.isBusy = false
      })
      this.toggleDetails(row);
    },
    toggleDetails(row) {
      if (row.item._showDetails) {
        this.$set(row.item, "_showDetails", false);
      } else {
        this.evolutionPerGrowthCycle.forEach((item) => {
          this.$set(item, "_showDetails", false);
        });

        this.$set(row.item, "_showDetails", true);
      }
    },
    setYAxes() {
      let maxValue = 3.0;
      let minValue = -3.0;

      this.datacollection?.datasets?.forEach((dataset) => {
        let datasetMax = 0;
        let datasetMin = 0;
        /*Array bereinigt, falls Werte undefined oder null sind, da sonst Math.max/min.apply nicht
        richtig funktioniert*/
        let cleanedDataset = [];

        dataset.data.forEach((data, index) => {
          if (data) {
            //data = data - data * 2; //invertierung rausgenommen 27.11.2024
            cleanedDataset.push(data);
            dataset.data[index] = data;
          }
        });

        datasetMax = Math.max.apply(Math, cleanedDataset);
        datasetMin = Math.min.apply(Math, cleanedDataset);

        if (datasetMax > maxValue) {
          maxValue = Math.ceil(datasetMax / 0.5) * 0.5;
        }
        if (datasetMin < minValue) {
          minValue = Math.floor(datasetMin / 0.5) * 0.5;
        }
      });
      this.datacollection.yMax = maxValue;
      this.datacollection.yMin = minValue;
    },
    assignSelectedSymbol(symbol = this.symbol) {
      let selectedSymbol = {
        data: symbol.dates
            .slice(0, this.max)
            .map((i) => i.values?.find((value) => value.max === this.max)?.zvalue)
            ?.reverse(),
        label: symbol.label,
        borderColor: symbol.borderColor,
        fill: symbol.fill,
        datum: symbol.dates
            .slice(0, this.max)
            .map((i) => i.date)
            .reverse(),
      };

      let json = Object.assign({}, selectedSymbol);
      this.datacollection.datasets = [json];
    },
    range(size, startAt = 0) {
      return [...Array(size).keys()].map((i) => i + startAt).reverse();
    },
    setSelectedGraphRange(range) {
      const otherRanges = this.graphRanges.filter((graphRange) => {
        return graphRange.range !== range.range;
      });
      otherRanges.forEach((other) => {
        other.selected = false;
      });
      range.selected = !range.selected;
      if (range.selected) {
        this.selectedGraphRange = range;
        this.max = range.range;
      } else {
        this.selectedGraphRange = {
          range: 27,
          selected: true,
        };
        this.max = 27;
      }
    },
    goToSymbol() {
      const routeData = this.$router.resolve({
        name: 'Symbols',
        params: {id: this.symbol.symbolId}
      });

      window.open(routeData.href, '_blank');
    }
  },
};
</script>

<style lang="scss">
.LPPEvolutionsPerGrowthCycle {
  width: 100%;
  min-height: 300px;
}

.goBack {
  cursor: pointer;
  width: fit-content;
}

.goBack:hover {
  color: #a6ccd7;
}

.filterInput {
  height: 38px;
}

#LPPEvolutionsPerGrowthCycleTbl .spinnerRow {
  min-height: 300px;
}

#LPPEvolutionsPerGrowthCycleTbl .lppSpinner {
  width: 4rem;
  height: 4rem;
}

.button_strikeevolution {
  border: none;
  background: transparent;
  box-shadow: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 7px;
  font-size: 0.813rem;
  padding: 5px;
  color: #000000;
  cursor: pointer;
}

.button_strikeevolution:hover {
  color: #858d8f;
}

.button_strikeevolution svg {
  font-size: 0.9rem;
  rotate: 0deg;
  transition: rotate 100ms ease-in-out;
}

tr.b-table-has-details .button_strikeevolution svg {
  rotate: 180deg;
}

tr.item > td:first-of-type {
  font-weight: 600;
  font-size: 0.875rem;
  vertical-align: center;
}

.noRows {
  color: rgb(209, 53, 53);
}

.selectedGrowthCycle .vs__dropdown-toggle {
  height: 38px;
}

#LppEvolutionPerGrowthCycle .graphRangeLinks {
  position: relative;
  text-align: left;
  margin-right: 3.2rem;
  z-index: 1;
}

#LppEvolutionPerGrowthCycle .graphRangeLink {
  display: inline-flex;
  color: #586483;
  font-weight: bold;
  margin-bottom: 0;
}

#LppEvolutionPerGrowthCycle .graphRangeLink:hover {
  color: #3e95cd;
  cursor: pointer;
}

.btn-nav {
  z-index: 10;
  position: absolute;
  right: 12px;
  top: 0;
  cursor: pointer;
}

.icon {
  height: 16px;
  width: 16px;
  margin-left: 10px;
  margin-bottom: 4px;
}
</style>
	  